<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th> # </v-table-th>
        <v-table-th>Data</v-table-th>
        <v-table-th>Subtotal </v-table-th>
        <!-- <v-table-th>Taxe </v-table-th> -->
        <v-table-th>Transport </v-table-th>
        <v-table-th>Total </v-table-th>
        <v-table-th>Status</v-table-th>
        <v-table-th class="text-center">Actiuni </v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          {{ row.id }}
        </v-table-td>
        <v-table-td>
          {{ formatDate(row.created_at) }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.subtotal) }}
        </v-table-td>
        <!-- <v-table-td>
          {{ formatPrice(row.tax) }}
        </v-table-td> -->
        <v-table-td>
          {{ formatPrice(row.shipping) }}
        </v-table-td>
        <v-table-td>
          {{ formatPrice(row.total) }}
        </v-table-td>
        <v-table-td>
          {{ row.status.label.toUpperCase() }}
        </v-table-td>

        <v-table-td class="text-center">
          <router-link
            :to="{
              name: 'orders.show',
              params: { orderId: row.id },
            }"
            class="text-blue-500 hover:text-blue-700"
          >
            Vizualizeaza
          </router-link>
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VTable from "./ui/VTable.vue";
import VTableTd from "./ui/VTableTd.vue";
import VTableTh from "./ui/VTableTh.vue";
import VTbody from "./ui/VTbody.vue";
import VThead from "./ui/VThead.vue";
import { priceFormatter } from "@/helpers";

export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
  },

  props: {
    rows: {
      type: Array,
    },
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },

    formatDate(date) {
      return new Date(date).toLocaleString();
    },
  },
};
</script>
