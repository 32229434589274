<template>
  <app-layout>
    <v-container>
      <user-tabs current="comenzi" />

      <page-section>
        <page-section-body>
          <form class="flex mb-2" @submit.prevent="index">
            <div class="sm:w-50 mr-2 md:ml-0">
              <v-label>Data </v-label>
              <v-input type="date" v-model="query.created_at" />
            </div>
            <v-button class="btn self-end">Filtreaza</v-button>
          </form>

          <loader v-if="loading" />
          <template v-else>
            <template v-if="totalRows">
              <orders-index-table :rows="rows" />
              <pagination
                @change:page="changePage($event)"
                :total="meta.total"
                :current_page="meta.current_page"
                :from="meta.from"
                :to="meta.to"
                :next_page_url="links.next"
                :prev_page_url="links.prev"
              />
            </template>
            <no-results v-else class="my-5" />
          </template>
        </page-section-body>
      </page-section>
    </v-container>
  </app-layout>
</template>

<script>
import ordersApi from "../api/resources/ordersApi";

import AppLayout from "../layout/AppLayout.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import OrdersIndexTable from "../components/OrdersIndexTable.vue";

import VInput from "../components/ui/VInput.vue";
import PageSection from "../components/ui/PageSection.vue";
import PageSectionBody from "../components/ui/PageSectionBody.vue";
import VContainer from "../components/VContainer.vue";
import UserTabs from "../components/UserTabs.vue";
import VButton from "../components/ui/VButton.vue";
import VLabel from '../components/ui/VLabel.vue';

export default {
  components: {
    AppLayout,
    Loader,
    Pagination,
    NoResults,
    OrdersIndexTable,

    VInput,
    PageSection,
    PageSectionBody,
    VContainer,
    // PageTitle,
    UserTabs,
    VButton,
    VLabel,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
      query: {
        created_at: "",
        page: 1,
      },
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    changePage(page) {
      this.query.page = page;
      this.index();
    },

    index() {
      this.loading = true;
      ordersApi
        .index({ ...this.query })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
