<template>
  <button
    class="
      inline-flex
      items-center
      justify-center
      py-2
      px-4
      border border-gray-300
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-gray-600
      bg-white
      hover:bg-gray-50
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100
    "
  >
    <slot></slot>
  </button>
</template>

<script>
export default {};
</script>

<style></style>
